import { IThemes } from 'src/interface/themes/index.interface';

const theme: IThemes = {
  palette: {
    primary: [
      '#101C42', // 0: Default /main
      '#BE570E', // 1: Hover
      '#E36711', // 2: Active  / Primary Button / Interactive
      '#F2DDC7', // 3: Border
      '#FFF9F2', // 4: Surface
      '#282A3A',
    ],
    secondary: [
      /* -------------------------------- TM GROUP -------------------------------- */
      '#6B0E0E', // 0: Pressed
      '#A41D28', // 1: Main
      '#F0D3D5', // 2: Border
      '#FFF7F8', // 3: Surface
      '#BD202C33', // 4: Focused

      /* -------------------------------- SM GROUP -------------------------------- */
      '#A16312', // 5: Pressed
      '#F5AC1C', // 6: Main
      '#F2E5CB', // 7: Border
      '#FFFBF5', // 8: Surface
      '#F5AC1C33', // 9: Focused
    ],
    color: [
      '#FEAC01', // 0: Orange
      '#42299a', // 1: Purple
      '#F75D81', // 2: Pink
      '#7ED321', // 3: LimeGreen
      '#39435f', // 4: BlueShade
      '#FFCA28', // 5: Yellow
      '#F2BD1B', // 6: Yellow Darken 5%
      '#3b5998', // 7: Facebook
      '#344e86', // 8: Facebook Darken 5%
      '#dd4b39', // 9: Google Plus
      '#d73925', // 10: Google Plus Darken 5%
      '#e14615', // 11: Auth0
      '#ca3f13', // 12: Auth0
      '#e0364c', // 13: themeColor--AlizarinCrimson
    ],
    warning: [
      '#E5A000', // 0: Warning
    ],
    success: [
      '#268E6C', // 0: Success
    ],
    error: [
      '#E53A34', // 0: Error
      '#EC3D3A', // 1: Darken 4%
      '#B02520', // 2: Lighten 8%
    ],
    grayscale: [
      '#1F262C', // 0: Neu 100
      '#50555B', // 1: Neu 90
      '#6E7377', // 2: Neu 80
      '#818589', // 3: Neu 70
      '#A6A9AC', // 4: Neu 60
      '#C7C9CA', // 5: Neu 50
      '#E3E4E5', // 6: Neu 40
      '#EFEFF0', // 7: #Neu 30
      '#F6F6F6', // 8: Neu 20
      '#FFFFFF', // 9: Neu 10
    ],
    text: [
      '#323332', // 0: Heading
      '#595959', // 1: HeadingLight
      '#979797', // 2: Text
      '#797979', // 3: TextDark
      '#6a6c6a', // 4: Heading Lighten 22%
    ],
    border: [
      '#e9e9e9', // 0: Border
      '#d8d8d8', // 1: BorderDark
      '#ebebeb', // 2: BorderLight
      '#d3d3d3', // 3:
      'rgba(228, 228, 228, 0.65)', // 4:
    ],
  },

  fonts: {
    primary: 'Rubik, sans-serif',
    pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
  },
};

// link theme : https://www.figma.com/file/CSlYm7btb5EBD01xYSCPsh/Siswamedia-Revamp-2021?node-id=423%3A14511

export default theme;
