import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';
import { RouteProps, useLocation } from 'react-router';
import { useAppSelector } from '../configs/hooks.config';

const PrivateRoute: FC<RouteProps> = (props) => {
  const state = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  return state.token ? (
    (props.element as React.ReactElement)
  ) : (
    <Result
      status="403"
      title="403"
      subTitle={'UnAuthrized'}
      extra={
        <Button
          type="primary"
          onClick={() =>
            navigate(`/login${'?from=' + encodeURIComponent(location.pathname)}`, { replace: true })
          }>
          Go To Login
        </Button>
      }
    />
  );
};

export default PrivateRoute;
