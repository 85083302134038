import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AUTH } from '../../../constants/slicer.constant';

export interface StateInterface {
  token?: string | null;
  refresh_token?: string | null;
  expires_in?: number | null;
  userData?: any | null;
}

const initialState: StateInterface = {
  token: null,
  refresh_token: null,
  expires_in: null,
  userData: null,
};
export const indexSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    addToken: (state, action: PayloadAction<string>) => {
      localStorage.setItem('activeToken', action.payload);
      state.token = action.payload;
    },
    addRefreshToken: (state, action: PayloadAction<string>) => {
      state.refresh_token = action.payload;
    },
    addExpiresIn: (state, action: PayloadAction<number>) => {
      state.expires_in = action.payload;
    },
    clearLoggedUser: (state) => {
      state.token = null;
      state.refresh_token = null;
      state.expires_in = null;
      state.userData = null;
    },
  },
});

export const { addExpiresIn, addRefreshToken, addToken, clearLoggedUser } = indexSlice.actions;
export default indexSlice.reducer;
