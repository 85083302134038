import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WORKER } from '../../../constants/slicer.constant';
import { ResponseListWorker } from '../../../services/whatsapp/response.whatsapp';

export interface StateInterface {
  data: ResponseListWorker[];
  selectData: ResponseListWorker | null;
}

const initialState: StateInterface = {
  data: [],
  selectData: null,
};
export const indexSlice = createSlice({
  name: WORKER,
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<ResponseListWorker[]>) => {
      state.data = action.payload;
    },
    selectData: (state, action: PayloadAction<ResponseListWorker>) => {
      state.selectData = action.payload;
    },
    clearSelectData: (state) => {
      state.selectData = null;
    },
  },
});

export const { setData, selectData, clearSelectData } = indexSlice.actions;
export default indexSlice.reducer;
