import { FC } from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const SuspensePage: FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="Loading . . ."
      subTitle={'Waiting Loading Assets'}
      extra={
        <Button type="primary" onClick={() => navigate('/')}>
          Kembali ke Home
        </Button>
      }
    />
  );
};

export default SuspensePage;
