import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WHITELABEL } from '../../../constants/slicer.constant';
import {
  whitelabelAttributeProperty,
  whitelabelPartnerProperty,
  whitelabelProperty,
} from '../../../services/whitelabel/response.whitelabel';

export interface StateInterface {
  data: whitelabelProperty[];
  selectData: whitelabelProperty | null;
  dataAttribute: whitelabelAttributeProperty[] | [];
  selectDataAttributeValue: whitelabelAttributeProperty | null;
  selectDataPartner: whitelabelPartnerProperty | null;
  dataPartner: whitelabelPartnerProperty[] | [];
}

const initialState: StateInterface = {
  data: [],
  selectData: null,
  dataAttribute: [],
  selectDataAttributeValue: null,
  dataPartner: [],
  selectDataPartner: null,
};
export const indexSlice = createSlice({
  name: WHITELABEL,
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<whitelabelProperty[]>) => {
      state.data = action.payload;
    },
    selectData: (state, action: PayloadAction<whitelabelProperty>) => {
      state.selectData = action.payload;
    },
    clearSelectData: (state) => {
      state.selectData = null;
    },
    setDataPartner: (state, action: PayloadAction<whitelabelPartnerProperty[]>) => {
      state.dataPartner = action.payload;
    },
    setSelectDataPartner: (state, action: PayloadAction<whitelabelPartnerProperty>) => {
      state.selectDataPartner = action.payload;
    },
    clearSelectDataPartner: (state) => {
      state.selectDataPartner = null;
    },
    setDataAttribute: (state, action: PayloadAction<whitelabelAttributeProperty[]>) => {
      state.dataAttribute = action.payload;
    },
    selectDataAttribute: (state, action: PayloadAction<whitelabelAttributeProperty>) => {
      state.selectDataAttributeValue = action.payload;
    },
    clearSelectDataAttribute: (state) => {
      state.selectDataAttributeValue = null;
    },
  },
});

export const {
  setData,
  selectData,
  clearSelectData,
  setDataAttribute,
  clearSelectDataPartner,
  clearSelectDataAttribute,
  setDataPartner,
  setSelectDataPartner,
} = indexSlice.actions;
export default indexSlice.reducer;
