import { Suspense } from 'react';
import { Spin, ConfigProvider } from 'antd';

import 'moment/locale/id';
import RenderRouter from './routes';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import themes from 'src/themes';
import SuspensePage from './components/SuspensePage';
import logo from './logo.svg';
import Favicon from 'react-favicon';
const App: React.FC = () => {
  const loading = false;

  return (
    <ConfigProvider componentSize="middle">
      <ThemeProvider theme={themes.themedefault}>
        <BrowserRouter>
          <Suspense fallback={<SuspensePage />}>
            <Spin
              spinning={loading}
              className="app-loading-wrapper"
              data-testid="main-app"
              tip="loading"
            />
            <Favicon url={logo} />
            <RenderRouter />
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </ConfigProvider>
  );
};

export default App;
