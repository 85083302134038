import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';
import WrapperRouteComponent from './config';
import { useRoutes } from 'react-router-dom';

/* ------------------------------ IMPORT GROUP ------------------------------ */
const NotFound = lazy(() => import(/* webpackChunkName: "404'"*/ 'src/pages/404'));
const LayoutPage = lazy(() => import(/* webpackChunkName: "layout'"*/ 'src/pages/layout'));
const LoginPage = lazy(() => import(/* webpackChunkName: "login'"*/ 'src/pages/login'));
const DashboardPage = lazy(() => import(/* webpackChunkName: "dashboard'"*/ 'src/pages/dashboard'));

const WhitelabelPage = lazy(
  () => import(/* webpackChunkName: "whitelabel'"*/ 'src/pages/whitelabel'),
);
const WhitelabelPageDetail = lazy(
  () => import(/* webpackChunkName: "whitelabel-detail'"*/ 'src/pages/whitelabel/Detail'),
);
const WhitelabelPartnerPage = lazy(
  () => import(/* webpackChunkName: "whitelabel-partner'"*/ 'src/pages/whitelabel-partner'),
);
const SchoolPage = lazy(() => import(/* webpackChunkName: "schools'"*/ 'src/pages/schools'));
const SchoolDetailPage = lazy(
  () => import(/* webpackChunkName: "school-detail'"*/ 'src/pages/schools/Detail'),
);
const SchoolFormPage = lazy(
  () => import(/* webpackChunkName: "school-edit'"*/ 'src/pages/schools/Form'),
);
const GeneratePage = lazy(() => import(/* webpackChunkName: "school-edit'"*/ 'src/pages/generate'));

const routeList: RouteObject[] = [
  {
    path: '/login',
    element: <WrapperRouteComponent element={<LoginPage />} titleId="Login" />,
  },
  {
    path: '/',
    element: <WrapperRouteComponent auth={true} element={<LayoutPage />} titleId="" />,
    children: [
      {
        path: '',
        element: <Navigate to="login" />,
      },
      {
        path: 'dashboard',
        element: (
          <WrapperRouteComponent auth={true} element={<DashboardPage />} titleId="Dashboard" />
        ),
      },
      {
        path: 'schools',
        element: (
          <WrapperRouteComponent auth={true} element={<SchoolPage />} titleId="Manage Schools" />
        ),
      },
      {
        path: 'schools/detail/:id',
        element: (
          <WrapperRouteComponent
            auth={true}
            element={<SchoolDetailPage />}
            titleId="Detail School"
          />
        ),
      },
      {
        path: 'schools/edit/:id',
        element: (
          <WrapperRouteComponent auth={true} element={<SchoolFormPage />} titleId="Edit School" />
        ),
      },
      {
        path: 'whitelabel',
        element: (
          <WrapperRouteComponent auth={true} element={<WhitelabelPage />} titleId="Whitelabel" />
        ),
      },
      {
        path: 'whitelabel/detail/:id',
        element: (
          <WrapperRouteComponent
            auth={true}
            element={<WhitelabelPageDetail />}
            titleId="Whitelabel Detail"
          />
        ),
      },
      {
        path: 'whitelabel-partner',
        element: (
          <WrapperRouteComponent
            auth={true}
            element={<WhitelabelPartnerPage />}
            titleId="Whitelabel Partner"
          />
        ),
      },
      {
        path: 'generate',
        element: (
          <WrapperRouteComponent auth={true} element={<GeneratePage />} titleId="Generate Link" />
        ),
      },
      {
        path: '*',
        element: <WrapperRouteComponent element={<NotFound />} titleId="notfound" />,
      },
    ],
  },
];

const RenderRouter: FC = () => {
  return useRoutes(routeList);
};

export default RenderRouter;
