import { FC, ReactElement } from 'react';
import { RouteProps } from 'react-router';
import PrivateRoute from './privateRoute';

export interface WrapperRoutePropsTemp {
  /** document title locale id */
  titleId: string;
  /** authorization？ */
  auth?: boolean;
}

export type WrapperRouteProps = WrapperRoutePropsTemp & RouteProps;

const WrapperRouteComponent: FC<WrapperRouteProps> = ({ titleId, auth, ...props }) => {
  if (titleId) {
    document.title = titleId;
  }

  return auth ? <PrivateRoute {...props} /> : (props.element as ReactElement);
};

export default WrapperRouteComponent;
